import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const Spikes = ({ color, zIndex = 0 }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: "20px",
    },
    spikes: {
      position: "relative",
      background: color,
      zIndex: zIndex,
      "&::after": {
        content: "''",
        position: "absolute",
        right: "0",
        left: "-0%",
        top: "100%",
        zIndex: "10",
        display: "block",
        height: "85px",
        backgroundSize: "85px 100%",
        backgroundImage: `linear-gradient(135deg, ${color} 25%, transparent 25%), linear-gradient(225deg,${color} 25%, transparent 25%)`,
        backgroundPosition: " 0 0",
      },
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <section className={classes.spikes}></section>
    </div>
  );
};

export default Spikes;
