import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Wallets from "./Header.Wallets";
import useWeb3 from "../../hooks/useWeb3";
import NetworkMessage from "./Header.Network";
import { useBlockchainContext } from "../../contexts/blockchain";
import Blockies from "react-blockies";
import { useHistory } from "react-router-dom";
import { parseNetwork } from "../../utils";
import IconSvg from "../../images/icon.svg";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 100,
    "&::focus": {
      outline: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  header: {
    boxShadow: "none",
  },

  icon: {
    marginRight: "5px",
  },
  menuTitle: {
    fontSize: "20px",
    padding: "16px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  headerTitle: {
    cursor: "pointer",
    fontSize: "20px",
    marginRight: "20px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  wallet: {},
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const { connect, enable, check } = useWeb3();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  // @ts-ignoreconst
  const {
    store: { address, open, networkId },
    actions: { setOpen },
  } = useBlockchainContext();

  const handleClickConnect = useCallback(async () => {
    if (await check()) {
      try {
        await connect();
      } catch (err) {
        await enable();
      }
    } else {
      setOpen(true);
    }
  }, [check, connect, enable, setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSell = useCallback(() => {
    history.push("/sell-item");
  }, [history]);

  const handleMyPositions = useCallback(() => {
    history.push("/me");
  }, [history]);

  const handleHome = useCallback(() => {
    history.push("/");
  }, [history]);

  const handleContact = useCallback(() => {
    history.push("/aboutus");
  }, [history]);

  const anchor = "right";
  const CheckLogin = () => {
    if (address) {
      if (matches) {
        // Mobile menu
        return (
          <React.Fragment>
            <IconButton onClick={toggleDrawer(anchor, true)}>
              <Blockies seed={address || "none"} className="identicon" />
            </IconButton>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              <div
                className={clsx(classes.list, {
                  [classes.fullList]: anchor === "top" || anchor === "bottom",
                })}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
              >
                <h2 className={classes.menuTitle}>Menu</h2>
                <Divider />
                <List>
                  <ListItem button>
                    <ListItemText
                      primary={"My Positions"}
                      onClick={handleMyPositions}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary={"Sell"} onClick={handleSell} />
                  </ListItem>
                  <ListItem button>
                    <ListItemText
                      primary={"About us"}
                      onClick={handleContact}
                    />
                  </ListItem>
                </List>
              </div>
            </SwipeableDrawer>
          </React.Fragment>
        );
      } else {
        // Window menu
        return (
          <React.Fragment>
            <Button color="inherit" onClick={handleMyPositions}>
              My Positions
            </Button>
            <Button color="inherit" onClick={handleSell}>
              Sell
            </Button>
            <Button color="inherit" onClick={handleContact}>
              About us
            </Button>
            <IconButton>
              <Blockies seed={address || "none"} className="identicon" />
            </IconButton>
          </React.Fragment>
        );
      }
    } else {
      if (matches) {
        return (
          <React.Fragment>
            <IconButton onClick={toggleDrawer(anchor, true)}>
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              <div
                className={clsx(classes.list, {
                  [classes.fullList]: anchor === "top" || anchor === "bottom",
                })}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
              >
                <h2 className={classes.menuTitle}>Menu</h2>
                <Divider />
                <List>
                  <ListItem button>
                    <ListItemText
                      primary={"Login"}
                      onClick={handleClickConnect}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemText
                      primary={"About us"}
                      onClick={handleContact}
                    />
                  </ListItem>
                </List>
              </div>
            </SwipeableDrawer>
          </React.Fragment>
        );
      } else {
        return (
          <>
            <Button color="inherit" onClick={handleClickConnect}>
              Login
            </Button>
            <Button color="inherit" onClick={handleContact}>
              About us
            </Button>
          </>
        );
      }
    }
  };

  return (
    <>
      <AppBar position="fixed" color="transparent" className={classes.header}>
        <Toolbar>
          <Typography className={classes.title}>
            <span className={classes.headerTitle} onClick={handleHome}>
              <img
                src={IconSvg}
                width="18"
                className={classes.icon}
                alt="logo"
              />
              Store721
            </span>
            {parseNetwork(networkId)}
          </Typography>

          <CheckLogin></CheckLogin>
        </Toolbar>
      </AppBar>
      <Wallets
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        className={classes.wallet}
      />
      <NetworkMessage />
    </>
  );
};

export default Header;
