import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { getPositions, getMyPositions } from "../services";
import PositionKovan from "../static/kovan/Position.json";
import PositionDispacherKovan from "../static/kovan/PositionDispatcher.json";
import PositionMainnet from "../static/mainnet/Position.json";
import PositionDispacherMainnet from "../static/mainnet/PositionDispatcher.json";
import { parseNetwork } from "../utils";

const EnabledNetworks = {
  "42": "KOVAN",
  "1": "MAINET",
};

const initialState = {
  address: null,
  error: "",
  contracts: null,
  web3: null,
  networkId: parseInt(process.env.REACT_APP_DEFAULT_NETWORK_ID || "1", 10),
  network: parseNetwork(
    parseInt(process.env.REACT_APP_DEFAULT_NETWORK_ID || "1", 10)
  ),
  networkError: false,
  instances: {},
  positions: null
};

const BlockchainContext = createContext({});

export function useBlockchainContext() {
  return useContext(BlockchainContext);
}

const reducers = (state, action) => {

  switch (action.type) {
    case "SET_CONTRACTS_DATA": {
      return {
        ...state,
        ...{
          contracts: action.data,
        },
      };
    }
    case "SET_POSITIONS_DATA": {
      return {
        ...state,
        ...{
          positions: action.data,
        },
      };
    }
    case "ERROR_POSITIONS_DATA": {
      return {
        ...state,
        ...{
          positions: null,
        },
      };
    }
    case "SET_MY_POSITIONS_DATA": {
      return {
        ...state,
        ...{
          mypositions: action.data,
        },
      };
    }
    case "ERROR_MY_POSITIONS_DATA": {
      return {
        ...state,
        ...{
          mypositions: null,
        },
      };
    }
    case "SET_WEB3": {
      return {
        ...state,
        ...{
          web3: action.data,
        },
      };
    }
    case "SET_ADDRESS": {
      return {
        ...state,
        ...{
          address: action.data,
        },
      };
    }
    case "SET_INSTANCE": {
      return {
        ...state,
        ...{
          instances: action.data,
        },
      };
    }
    case "SET_OPEN": {
      return {
        ...state,
        ...{
          open: action.data,
        },
      };
    }
    case "SET_NETWORK": {
      return {
        ...state,
        ...{
          networkId: action.data.networkId,
          network: action.data.network,
          positions: null,
        },
      };
    }
    case "SET_DATA_NET": {
      return {
        ...state,
        ...{
          networkId: action.data.networkId,
          network: action.data.network,
          positions: action.data.positions,
          mypositions: action.data.mypositions,
          address: action.data.address,
          web3: action.data.web3
        },
      };
    }
    case "SET_NETWORK_ERROR": {
      return {
        ...state,
        ...{
          networkError: action.data,
        },
      };
    }
    default:
      return state;
  }
}




export function Updater() {
  // 
  return null;
}

export default function Provider({ children }) {
  const [store, dispatch] = useReducer(reducers, {}, () => initialState);
  const setWeb3 = useCallback((newWeb3) => dispatch({ type: "SET_WEB3", data: newWeb3 }), []);
  const setNewInstance = useCallback((instances) =>
    dispatch({ type: "SET_INSTANCE", data: instances }), []);

  const setCheckBlockchain = useCallback((response) =>
    dispatch({ type: "SET_BLOCKCHAIN_CHECK", response }), []);

  const fetchContracts = async () => {
    try {
      return dispatch({
        type: "SET_CONTRACTS_DATA",
        data: {
          "42": { Position: PositionKovan, PositionDispacher: PositionDispacherKovan },
          "1": { Position: PositionMainnet, PositionDispacher: PositionDispacherMainnet }
        },
      });
    } catch (error) {
      return dispatch({ type: "ERROR_CONTRACTS_DATA" });
    }
  };
  const fetchPositions = async (net) => {
    try {
      const resp = await getPositions(net);
      return dispatch({
        type: "SET_POSITIONS_DATA",
        data: resp,
      });
    } catch (error) {
      return dispatch({ type: "ERROR_POSITIONS_DATA" });
    }
  };

  const fetchMyPositions = async (net, address) => {
    try {
      const resp = await getMyPositions(net, address);
      return dispatch({
        type: "SET_MY_POSITIONS_DATA",
        data: resp,
      });
    } catch (error) {
      return dispatch({ type: "ERROR__MYPOSITIONS_DATA" });
    }
  };


  const setNetworkData = async ({ address, networkId, web3 }) => {

    if (Object.keys(EnabledNetworks).indexOf(networkId.toString()) > -1 && address) {
      // Load information when we change network
      window.localStorage.setItem("eth_address", address);
      const positions = await getPositions(networkId);
      const mypositions = await getMyPositions(networkId, address);
      return dispatch({
        type: "SET_DATA_NET",
        data: {
          networkId,
          positions,
          mypositions,
          address,
          web3
        },
      });

    } else {
      return dispatch({
        type: "SET_NETWORK_ERROR",
        // @ts-ignore
        data: true,
      });
    }
  };

  const setOpen = (open) => dispatch({ type: "SET_OPEN", data: open });

  const values = { store, actions: { setWeb3, setNewInstance, setCheckBlockchain, fetchContracts, fetchPositions, fetchMyPositions, setOpen, setNetworkData } };

  return <BlockchainContext.Provider value={values}>{children}</BlockchainContext.Provider>;
}