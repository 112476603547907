import graphql from "graphql.js";
import { fromWei } from "web3-utils";
import { formatAmount, getExtraData } from "../utils";
import tokens from "../commons/tokens.json";

const getGraph = (networkId) => {
  let url = "";
  switch (parseInt(networkId)) {
    case 1:
      url = "https://api.thegraph.com/subgraphs/name/sendra/store721";
      break;
    case 42:
      url = "https://api.thegraph.com/subgraphs/name/sendra/store721-kovan";
      break;
    default:
      // Default Mainet
      url = "https://api.thegraph.com/subgraphs/name/sendra/store721";
      break;
  }
  console.log(' URL GRAPH :', url);
  return url;
}


const getTokensByContracts = async (web3, contract, address) => {

  const Contract721 = new web3.eth.Contract(
    contract.abi,
    contract.address
  );
  const ntokens = await Contract721.methods.balanceOf(address).call();
  const scannedTokens = [];
  if (ntokens > 0) {
    for (let i = 0; i < ntokens; i += 1) {
      const tokenId = await Contract721.methods
        .tokenOfOwnerByIndex(address, i)
        .call();
      scannedTokens.push(getExtraData(contract.address.toLowerCase(), tokenId));
    }
  }
  return scannedTokens;
}

export const getTokensByNetworkAndContract = async (web3, address, networkId) => {

  const contracts = Object.keys(tokens)
    .filter(
      (token) => tokens[token].network === parseInt(networkId)
    )
    .map((tokenKey, index) => tokens[tokenKey]);
  const request = contracts.map(async (contract) => getTokensByContracts(web3, contract, address));
  const results = await Promise.all(request);

  return results.reduce((prev, next) => {
    if (next && next.length > 0)
      prev = [...prev, ...next];
    return prev;
  }, []);
}

export const getTokens = async (address) => {
  // TODO: REFACTOR to promise.all
  const query = `{
    tokens(where:{owner:"${address}"}) {
      index
    }
  }`;

  const { tokens: goodsUnchained } = await graphql(
    "https://api.thegraph.com/subgraphs/name/unchainedgames/gods-unchained-tokens",
    {
      asJSON: true,
    }
  )(query)();


  const { cryptoKitties } = await graphql(
    "https://api.thegraph.com/subgraphs/name/thomasproust/cryptokitties-explorer", {
    asJSON: true
  }
  )(`{
    cryptoKitties(where: {owner: "${address}"}) {
      kittyId
    }
  }`)()
  const allTokens = [...(goodsUnchained.map(tok => { return { symbol: 'GOD', tokenId: tok.index, address: "0x0e3a2a1f2146d86a604adc220b4967a898d7fe07" } })), ...(cryptoKitties.map(tok => { return { symbol: 'CK', tokenId: tok.kittyId, address: "0x06012c8cf97bead5deae237070f9587f8e7a266d" } }))]
  return allTokens.map(myToken => {
    return getExtraData(myToken.address.toLowerCase(), myToken.tokenId);
  });
}

const transform = (positions = []) => {
  return positions.map((po) => {
    const extraData = getExtraData(po.tokenAddress.toLowerCase(), po.tokenId);
    return {
      ...po,
      ...{
        img: extraData.img,
        formatPrice: formatAmount(fromWei(po.price, "ether")),
        name: extraData.name,
        home: extraData.home,
        symbol: extraData.symbol,
      },
    };
  });
};
export const getPositions = async (networkId) => {

  // const query = `{
  //     positions {
  //       id
  //       address

  //       seller {
  //         id
  //       }
  //       buyer {
  //         id
  //       }
  //       tokenId
  //       tokenAddress
  //       price
  //     }
  //   }`;
  const query = `{
      nftokens(where: { approved: true }){
        approved
        actualPosition{
          id
          address
          seller {
            id
          }
          buyer {
            id
          }
          tokenId
          tokenAddress
          price
        }
      }
    }`;

  const { nftokens } = await graphql(
    getGraph(networkId),
    {
      asJSON: true,
    }
  )(query)();

  const positions = nftokens.map(tok => {
    console.log('APPROVED: ', tok.approved)
    return tok.actualPosition
  });
  return transform(positions);
};

export const getMyPositions = async (networkId, address) => {
  const query = `{
      positions(where: {seller: "${address.toLowerCase()}" } ) {
        id
        address
        seller {
          id
        }
        buyer {
          id
        }
        tokenId
        tokenAddress
        price
        nftoken{
          approved
          actualPosition{
            id
          }
        }
      }
    }`;

  const { positions } = await graphql(
    getGraph(networkId),
    {
      asJSON: true,
    }
  )(query)();
  return transform(positions);
};
