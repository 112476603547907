import { useBlockchainContext } from '../contexts/blockchain'
import useInterval from "./useInterval";


const useWallet = () => {
  const {
    store: {
      web3, networkId, address,
    },
    actions: {
      setNetworkData,
    },
  } = useBlockchainContext();

  useInterval(async () => {
    try {
      if (web3) {
        const net = await web3.eth.net.getId();
        const accounts = await web3.eth.getAccounts();
        if (net !== networkId || (accounts && accounts.length > 0 && address !== accounts[0])) {
          console.log('>>>> CHECK ', net, accounts[0]);
          setNetworkData({ web3, address: accounts[0], networkId: net })
        }
      }
    } catch (error) {
      console.error("NO_NETWORK", error);
    }
  }, 2000);

  return !!web3;
};

export default useWallet;
