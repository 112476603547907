import React from "react";
import Spikes from "../Spikes";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    background: theme.palette.primary.main,
    minHeight: "100px",
    marginTop: "100px",
    color: "white",
    bottom: "0",
  },
  container: {
    marginTop: "50px",
    background: "transparent",
  },
  submenu: {
    float: "right",
    marginBottom: "20px",
    "& a": {
      color: "white",
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      float: "left",
      marginTop: "30px",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  /* eslint-disable */
  return (
    <>
      <div className={classes.root}>
        <Spikes color="#fafafc" />
        <Container className={classes.container}>
          Created with ❤️ from Barcelona
          <div className={classes.submenu}>
            <a
              href="https://gitlab.com/damarnez/openshop-contracts"
              target="_blank"
            >
              Contracts
            </a>{" "}
            | <a href="/aboutus">About us</a>
          </div>
        </Container>
      </div>
    </>
  );
  /* eslint-enable */
};

export default Footer;
