import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./views/App";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import BlockainContext from './contexts/blockchain'
import FollowTX from "followtx";
import Web3 from "web3";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace",
  },
  palette: {
    primary: {
      main: "#f7b201",
      contrastText: "#ffffff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: "#fafafc",
    },
    text: {
      primary: "rgb(76, 76, 76)",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        margin: "10px",
        padding: "10px 40px 10px 40px",
      },
    },
  },
});

const Root = () => {

  const followTx = new FollowTX(
    new Web3("wss://kovan.infura.io/ws/v3/e0c246b2761d4864b79949d719f3208a")
  );

  return (
    <BlockainContext>
      <CssBaseline />
      <ThemeProvider theme={theme}>


        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SnackbarProvider>

      </ThemeProvider>
    </BlockainContext>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
