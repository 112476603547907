import { browserName } from "react-device-detect";
import numeral from "numeral";
import PlaceHolderImg from "../images/placeholder.jpg";
import CryptoWallets from "../commons/cryptoWallets";
import tokens from "../commons/tokens.json";

const numeralFormat = "0[.]0,0";
numeral.defaultFormat(numeralFormat);
export const parseNetwork = (id) => {
  switch (id) {
    case 1:
      return "mainnet";
    case 3:
      return "ropsten";
    case 4:
      return "rinkeby";
    case 5:
      return "goerli";
    case 42:
      return "kovan";
    case 6969:
      return "test";
    default:
      return "private";
  }
};

export const isSupportedBrowser = () =>
  ["brave", "chrome", "chromium", "firefox", "opera"].some((supportedBrowser) =>
    browserName.toLowerCase().includes(supportedBrowser)
  );

export const getWalletName = (walletId) => {
  switch (walletId) {
    case CryptoWallets.Metamask:
      return "Metamask";
    case CryptoWallets.Opera:
      return "Opera";
    case CryptoWallets.Coinbase:
      return "Coinbase";
    default:
      return "Unknow";
  }
};

export const getExtraData = (address, tokenId) => {
  console.log('address', address)
  const data = tokens[address];
  if (data) {
    return {
      img: `${tokens[address].img}${tokenId}${
        tokens[address].ext ? `.${tokens[address].ext}` : ""
        }`,
      name: tokens[address].name,
      home: tokens[address].home,
      symbol: tokens[address].symbol,
      tokenId,
      address
    };
  }
  //If we don't have the mapping
  return {
    img: PlaceHolderImg,
    name: "Unknown Token",
    home: "https://shop721.io",
    tokenId,
    address
  };
};

export const formatAmount = (amount) => {
  return numeral(amount).format();
};

export const calculatedWidth = (symbol) => {
  switch (symbol) {
    case "GOD":
      return "250";
    case "CK":
      return "295";
    default:
      return "295";
  }
};
